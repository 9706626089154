#slider{
 
    .box {

        // width: 380px;
        padding: 30px;
        border-radius: 16px;
        background: #F7F7F7;
        height: 210px;
    }

    .upperpart {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .picbox {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .upperhead {
        color: #3A3B3F;

        /* Text lg/Medium */
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 155%;
        /* 27.9px */
        letter-spacing: -0.36px;
        text-align: left;
    }

    .uperpaar {
        color: #6D727D;
        margin-top: 2px;
        /* Text sm/Regular */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        text-align: left;
    }

    .reviews {
        color: #6D727D;
        margin-top: 10px;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        text-align: left;
    }


    .carousel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin: auto;
        flex-wrap: wrap;
        
    }
}