#aboutcontainer {

    .bg-body-tertiary {
        background-color: transparent !important;
    }

    // .nav {
    //     color: #3A3B3F;


    //     font-family: Poppins !important;
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 300;
    //     line-height: 150%;
    //     /* 24px */
    //     letter-spacing: -0.32px;

    // }
.frameimg{
    display: none;
}

    // .navbarmain {
    //     padding: 0px 137px;
    // }


    .newbtn {

        align-items: center;
        border: 1px solid #EF4444;
        background-color: transparent !important;
        color: #3A3B3F;
        padding: 10px 40px;
        border-radius: 100px;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;

    }

    .signin {
        align-items: center;

        border: 1px solid #EF4444;

        padding: 10px 40px;
        border-radius: 100px;
        background: #EF4444;
        color: #FFF;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }



    .aboutus-firstbox {
        
        padding: 120px;
        background: rgb(252,214,214);
        background: linear-gradient(0deg, rgba(252,214,214,1) 0%);
    }

    .aboutus-firstabout{
        color:  #3A3B3F;
text-align: center;

/* Text md/Medium */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
letter-spacing: -0.32px;
margin-bottom: 20px;
    }

    .aboutus-firsthead{
        color:  #3A3B3F;
text-align: center;

/* lg/Medium */
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: 126%; /* 60.48px */
letter-spacing: -0.96px;
width: 57%;
margin: auto;

    }
    .aboutus-firstpara{
        color:  #575A63;
        text-align: center;
        
        /* Text xl/Regular */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 30px */
        letter-spacing: -0.4px;
    }


    .Security-section-secondbox{
        padding: 120px 120px 60px 120px;
    }

    .Security-section-compflex{
        display: flex;
        flex-direction: row;
         gap: 280px;
        justify-content: space-between;
    }
    .Security-section-compimg{
        width:30%;
    height: auto;
        border-radius: 16px;
    }

    .Security-section-comptext >h1 {
        color:  #3A3B3F;
          width:92%;
margin-top: 50px;
/* lg/Medium */
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: 126%; /* 60.48px */
letter-spacing: -0.96px;
text-align: left;

    }

    .Security-section-comptext >h1 >p  {
        color:  #6D727D;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 24px */
        letter-spacing: -0.32px;
        text-align: left;
        margin-top: 10px;
      
    }

    .Security-section-comptext >h1 >h3  {
        color:  #6D727D;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 24px */
        letter-spacing: -0.32px;
        text-align: left;
        margin-top: 20px;
    }

.Security-section-newsecondpara
    {
        color:  #6D727D;

/* Text md/Regular */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 24px */
letter-spacing: -0.32px;
text-align: left;
margin-top: 20px;
// margin-left: 2px;

    }

    .Security-section-signsignnewsecondpara  {
         color:  #6D727D;
        font-family: Inspiration;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
margin-top: 30px;
font-family: 'Great Vibes', cursive; 
margin-left: 2px
// color: #6c7a89;

    }




    .opportunity-section-fifthbox {
        width: 88%;
        margin: auto;
        margin-top: 60px;
    }

    .opportunity-section-mainbox {
        display: flex;
        flex-direction: row;
        gap: 60px;
        border-radius: 36px;
        background: #EF4444;
        padding: 60px 120px;
    }

    .opportunity-section-mainboxheading {
        color: #FFF;
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 129%;
        /* 46.44px */
        letter-spacing: -0.72px;
        text-align: left;
        margin-top: 22px;
    }

    .opportunity-section-mainboxpara {
        color: #FFF;
        text-align: left;
        /* Text lg/Regular */
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 155%;
        /* 27.9px */
        letter-spacing: -0.36px;
        margin-top: 20px;
        margin-bottom: 60px;
    }

    .opportunity-section-fifthimage {
        width: 50%;
    }

    .opportunity-section-fiftbtn {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .opportunity-section-fifthfirstbtn {
        align-items: center;

        border: 1px solid #FFF;

        padding: 16px 40px;
        border-radius: 100px;
        background: #FFF;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }

    .opportunity-section-fifthsecondbtn {

        align-items: center;

        border: 1px solid #FFF;


        padding: 16px 40px;
        border-radius: 100px;
        background: transparent !important;
        color: white;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }


    .review-section-sixbox{
        margin-top: 120px;
    }


    .review-section-sixboxpara {
        color: #6D727D;
        text-align: center;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        margin-top: 4px;
        padding-bottom: 60px;
    }






    .footer {
        padding: 60px 120px;
        margin-top: 120px;
        // width: 50%;
        // margin: auto;
        background: rgb(252, 224, 224);
        background: linear-gradient(0deg, rgba(252, 224, 224, 1) 0%, rgba(229, 231, 232, 1) 100%, rgba(0, 0, 0, 1) 100%);
    }

    .foootermain {
        width: 50%;
        margin: auto;
    }

    .footerlogo {
        width: 30%;
        margin-bottom: 10px;
        // width: 130px;
        // height: 91px;
    }

    .footerpara {
        color: #6D727D;
        text-align: center;

        /* Text sm/Regular */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
        // width: 70%;
        margin: auto;
    }

    .social {
        width: 10%;
    }


    .links {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 50%;
        margin: auto;
    }

    .linksname {
        color: #3A3B3F;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }


    
    .termslinks{
        display: flex;
        gap: 100px;
        flex-direction: row;
        justify-content: center;
        margin: auto;
       
    }

    .termslinksname {
        color: #3A3B3F;
        margin-top: 20px;
        margin-bottom: 20px;
        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }


  
        @media only screen and (max-width:1460px) {
          .opportunity-section-mainbox{
              padding: 60px 70px;
          }
  
          .opportunity-section-mainboxheading{
              width: 50%;
          }
      
        }


        @media only screen and (max-width:1330px) {
            .Security-section-compimg{
                width: 40%;
            }

            .Security-section-compflex{
                gap: 150px;
            }
        }


        @media only screen and (max-width:1100px) {
            .Security-section-compimg{
                width: auto;
            }

            .Security-section-compflex{
               flex-direction: column;
            }
        }
  
        @media only screen and (max-width:600px) {
  
        //   .navbarmain{
        //       padding: 0px 20px;
        //   }

        .termslinks{
            display: block;
        }
  
            .aboutus-firstabout {
            font-size: 14px;
           }
  
         .aboutus-firstbox , .Security-section-secondbox{
            padding: 60px 20px;
         }
     .aboutus-firsthead{
        font-size: 36px;
        width: auto;
     }
  .aboutus-firstpara{
    font-size: 16px;
  }

  .Security-section-compimg{
    width: 90%;
    margin: auto;
  }

  .Security-section-compflex {
display: block;
  } 

  .Security-section-comptext{
    width: 89%;
    margin: auto;
  }

  .Security-section-newsecondpara, .Security-section-signnewsecondpara{
    margin-top: 30px !important;
    // width: 89%;
    margin: auto;
    // font-family: 'Dancing Script', cursive;
    
  }
  .Security-section-comptext > h1 {
    font-size: 36px;
    width: auto;
  }


  .opportunity-section-fifthbox{
    margin-top: 0px;
    
  }

  .opportunity-section-mainbox{
    padding: 30px 20px;
    flex-direction: column;
    display: block;

  }

.opportunity-section-mainboxheading{
    width: auto;
}
          .opportunity-section-fiftbtn{
              flex-direction: column;
          }
  
          .opportunity-section-fifthimage{
              display: none;
          }

        //   .frameimg{
        //     display: block;
        //   }

        .frameimg{
            display: block;
  
            margin: auto;
          // display: block;
          width: 96%;
          padding-top: 40px;
          }
  
          .frameimg{
              display: block;
          }
  
          .opportunity-section-fifthfirstbtn ,.opportunity-sectionfifthsecondbtn, .opportunity-sectionfifthimage {
              width: auto;
          }
  
          .forthimg{
      width: auto;
          }
          .review-section-sixbox{
              padding: 60px 20px;
              margin-top: 20px;
          }
  
          .review-section-sixboxhead{
              padding: 0px;
          }
  
          .review-section-sixboxpara{
              padding-bottom: 30px;
          }
  
          .footer{
              padding: 60px 20px;
              margin-top: 20px;
          }
  .social{
      width: 20%;
  }
          .links{
              width: auto;
              flex-direction: column;
              gap: 16px;
          }

          .foootermain{
            width: auto;
          }
  
        }


        // @media only screen and (max-width:990px) {
        //     .navbar-nav, .navbar-nav-scroll {
        //         display: inline-flex;
        // //height: 882px;
        // max-height: none !important;
        // padding: 20px 20px 30px 20px;
        // flex-direction: column;
        // align-items: center;
        // gap: 40px !important;
        // background: #FFF;
        // //border-radius: 30px;
        // //border: 1px solid;
        // margin-left: none;
        // position: relative;
        // //left: 58%;
        // //width: 367px;
        // width: 100%;
        // // height: 882px;
        // z-index: 1;
        //     }
        
        //     .d-flex{
        //         flex-direction: column !important;
        //         justify-content: center !important;
        //     }
        
        //     .noright{
        //         right: 2px !important;
        //         position: relative;
        //     }
            
        // }

}