/* ---------------------------------------------------
    HELPERS SCSS
----------------------------------------------------- */




/* ---------------------------------------------------
      COMPONENT SCSS
    
  ----------------------------------------------------- */

  @import './slider.scss';
  @import './Navbar.scss';

/* ---------------------------------------------------
      PAGES SCSS
  ----------------------------------------------------- */

@import './Main.scss';
@import './aboutus.scss';
@import './smishing.scss';
@import './joinus.scss';
@import './contact.scss';
@import './privacy.scss';




@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&family=Lato&family=Montserrat:ital,wght@0,100;0,300;1,200&family=Poppins:wght@300;400;600;700&display=swap');





.justify-content-flex-end {
    justify-content: flex-end !important;
    display: flex;

}
.mr_10{
margin-right: 10px !important;
}

.w-100 {
    width: 100% !important;
}

textarea:focus, input:focus{
    outline: none;
}

.accordion-item h2 button {
    border-radius: 20px !important;
    background: #F3F7F5 !important;
    padding: 27px 40px !important;

}


// .nowidth{
//     width: auto !important;
// }

.accordion-item {
    border: none !important;
    border-radius: 20px !important;
    background: #F3F7F5 !important;
    margin-bottom: 25px !important;

}

.accordion-button {
    color: #1E2131;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.accordion-button:not(.collapsed) {
    color: #1E2131;
    box-shadow: none;
    border-color: none !important;

}

.bg-body-tertiary{
    background-color:none !important;
    opacity: none;
}