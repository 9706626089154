#navbar{
 
    .nav {
        color: #3A3B3F;


        font-family: Poppins !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;

    }



    .navbarmain {
        padding: 0px 137px;
    }


    .frameimg{
        display: none;
    }
    .newbtn {

        align-items: center;
        border: 1px solid #EF4444;
        background-color: transparent !important;
        color: #3A3B3F;
        padding: 10px 40px;
        border-radius: 100px;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;

    }

    .signin {
        align-items: center;

        border: 1px solid #EF4444;

        padding: 10px 40px;
        border-radius: 100px;
        background: #EF4444;
        color: #FFF;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }

    @media only screen and (max-width:600px) {

        .navbarmain{
            padding: 0px 20px;
        }
    }
    
@media only screen and (max-width:990px) {
    .navbar-nav, .navbar-nav-scroll {
        display: inline-flex;
//height: 882px;
max-height: none !important;
padding: 20px 20px 30px 20px;
flex-direction: column;
align-items: center;
gap: 40px !important;
background: #FFF;
//border-radius: 30px;
//border: 1px solid;
margin-left: none;
position: relative;
//left: 58%;
//width: 367px;
width: 100%;
// height: 882px;
z-index: 1;
    }

    .d-flex{
        flex-direction: column !important;
        justify-content: center !important;
    }

    .noright{
        right: 2px !important;
        position: relative;
    }
    
}
}