#smishingcontainer {

    .bg-body-tertiary {
        background-color: transparent !important;
    }


    .frameimg{
        display: none;
    }

    // .nav {
    //     color: #3A3B3F;


    //     font-family: Poppins !important;
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 300;
    //     line-height: 150%;
    //     /* 24px */
    //     letter-spacing: -0.32px;

    // }


    // .navbarmain {
    //     padding: 0px 137px;
    // }


    .newbtn {

        align-items: center;
        border: 1px solid #EF4444;
        background-color: transparent !important;
        color: #3A3B3F;
        padding: 10px 40px;
        border-radius: 100px;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;

    }

    .signin {
        align-items: center;

        border: 1px solid #EF4444;

        padding: 10px 40px;
        border-radius: 100px;
        background: #EF4444;
        color: #FFF;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }



    .Smishing-firstbox {
        
        padding: 120px;
        background: rgb(252,214,214);
        background: linear-gradient(0deg, rgba(252,214,214,1) 0%);
    }

    .Smishing-firstabout{
        color:  #3A3B3F;
text-align: center;

/* Text md/Medium */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
letter-spacing: -0.32px;
margin-bottom: 20px;
    }

    .Smishing-firsthead{
        color:  #3A3B3F;
text-align: center;

/* lg/Medium */
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: 126%; /* 60.48px */
letter-spacing: -0.96px;
width: 57%;
margin: auto;

    }
    .Smishing-firstpara{
        color:  #575A63;
        text-align: center;
        
        /* Text xl/Regular */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 30px */
        letter-spacing: -0.4px;
    }


    .Smishing-explain-secondbox{
        padding: 120px 120px 60px 120px;
    }

    .smashingsignin {
        align-items:center;
// justify-content: left;
justify-content: center;
        border: 1px solid #EF4444;
display: flex;
        padding: 16px 40px;
        border-radius: 100px;
        background: #EF4444;
        color: #FFF;
        text-align: center;
        width: 20%;
        margin-top: 30px;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }

    .Smishing-explain-compflex{
        display: flex;
        flex-direction: row;
         gap: 180px;
        justify-content: space-between;
    }
    .Smishing-explain-compimg{
        width:25%;
    
        border-radius: 16px;
    }

    .Smishing-explain-comptext >h1 {
        color:  #3A3B3F;
          width:92%;
margin-top: 50px;
/* lg/Medium */
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 126%; /* 60.48px */
letter-spacing: -0.96px;
text-align: left;

    }

    .Smishing-explain-comptext >h1 >p  {
        color:  #6D727D;

        /* Text md/Regular 
    */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 24px */
        letter-spacing: -0.32px;
        text-align: left;
        margin-top: 10px;
      
    }

    .Smishing-explain-comptext >h1 >h3  {
        color:  #6D727D;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 24px */
        letter-spacing: -0.32px;
        text-align: left;
        margin-top: 20px;
    }

.newsecondpara
    {
        color:  #6D727D;

/* Text md/Regular */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 24px */
letter-spacing: -0.32px;
text-align: left;
margin-top: 20px;
    }


.smishthirdbox{
    padding: 120px 120px 60px 120px;
}


.smsihingthird{
    display: flex;
    flex-direction: row;
    gap: 80px;
}


.smsihingthirdhead{
    color:  #3A3B3F;

/* md/Medium */
font-family: 'Poppins';
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 129%; /* 46.44px */
letter-spacing: -0.72px;
text-align: left;
width: 41;
}


.smsihingthirdpara{
color:  #6D727D;

/* Text md/Regular */
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 24px */
letter-spacing: -0.32px;
margin-top: 10px;
text-align: left;
}


.simpimg{
    width: 43%;
}


.smashingsigninwork {
    align-items:center;
// justify-content: left;
justify-content: center;
    border: 1px solid #EF4444;
display: flex;
    padding: 16px 40px;
    border-radius: 100px;
    background: #EF4444;
    color: #FFF;
    text-align: center;
    width: 22%;
    margin-top: 30px;

    /* Text sm/Medium */
    font-family: Poppins !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: -0.28px;
}


.smishing-mechansim-thirdbox {
    width: 97%;
    margin: auto;
    padding: 120px;
}


.smishing-mechansim-thirdheading {
    color: #3A3B3F;
    text-align: center;

    /* lg/Medium */
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 126%;
    /* 60.48px */
    letter-spacing: -0.96px;

}

.smishing-mechansim-thirdpara {
    color: #6D727D;
    text-align: center;

    /* Text xl/Medium */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 30px */
    letter-spacing: -0.4px;
    margin-top: 6px;
    margin-bottom: 60px;

}

.smishing-mechansim-mainflexdiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.smishing-mechansim-flexdiv {
    display: flex;
    flex-direction: column;
    gap: 120px
}

.smishing-mechansim-number {
    color: #EF4444;

    /* lg/Medium */
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 126%;
    /* 60.48px */
    letter-spacing: -0.96px;
    text-align: left;

}

.smishing-mechansim-numberhead {
    color: #3A3B3F;

    /* xs/Medium */
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%;
    /* 31.68px */
    letter-spacing: -0.48px;
    margin-top: 30;
    text-align: left;
}


.smishing-mechansim-numberpara {
    color: #6D727D;

    /* Text lg/Regular */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 155%;
    /* 27.9px */
    letter-spacing: -0.36px;
    text-align: left;

}

.smishing-mechansim-fleximg {
    width: 61%;
}

.fourthboxmain{
    display: flex;
    flex-direction: row;
    gap: 80px;
}
.fourthbox{
    padding: 60px 120px;
    width: 97%;
    margin: auto;
}

.simishingphone{
    width: 30%;
    border-radius: 16px;
}

.fourthsimphead{

color:  #3A3B3F;

/* md/Medium */
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 129%; /* 46.44px */
letter-spacing: -0.72px;
text-align: left;
}
.fourthsimphead > p {

    color:  #6D727D;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 150%;
letter-spacing: -0.32px;
margin-top: 30px;
text-align: left;


}





    .opportunity-fifthbox {
        width: 85%;
        margin: auto;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .opportunity-mainbox {
        display: flex;
        flex-direction: row;
        gap: 60px;
        border-radius: 36px;
        background: #EF4444;
        padding: 60px 120px;
    }

    .opportunity-mainboxheading {
        color: #FFF;
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 129%;
        /* 46.44px */
        letter-spacing: -0.72px;
        text-align: left;
        margin-top: 22px;
    }

    .opportunity-mainboxpara {
        color: #FFF;
        text-align: left;
        /* Text lg/Regular */
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 155%;
        /* 27.9px */
        letter-spacing: -0.36px;
        margin-top: 20px;
        margin-bottom: 60px;
    }

    .opportunity-fifthimage {
        width: 50%;
    }

    .opportunity-fiftbtn {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .opportunity-fifthfirstbtn {
        align-items: center;

        border: 1px solid #FFF;

        padding: 16px 40px;
        border-radius: 100px;
        background: #FFF;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }

    .opportunity-fifthsecondbtn {

        align-items: center;

        border: 1px solid #FFF;


        padding: 16px 40px;
        border-radius: 100px;
        background: transparent !important;
        color: white;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }


  
.sixbox{
    padding: 60px 120px 120px 120px;

    width: 97%;
    margin: auto;
}

  


    .footer {
        padding: 60px 120px;
        margin-top: 60px;
        // width: 50%;
        // margin: auto;
        background: rgb(252, 224, 224);
        background: linear-gradient(0deg, rgba(252, 224, 224, 1) 0%, rgba(229, 231, 232, 1) 100%, rgba(0, 0, 0, 1) 100%);
    }

    .foootermain {
        width: 50%;
        margin: auto;
    }

    .footerlogo {
        width: 30%;
        margin-bottom: 10px;
        // width: 130px;
        // height: 91px;
    }

    .footerpara {
        color: #6D727D;
        text-align: center;

        /* Text sm/Regular */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
        // width: 70%;
        margin: auto;
    }

    .social {
        width: 10%;
    }


    .links {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 50%;
        margin: auto;
    }

    .linksname {
        color: #3A3B3F;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }

    .termslinks{
        display: flex;
        gap: 100px;
        flex-direction: row;
        justify-content: center;
        margin: auto;
       
    }

    .termslinksname {
        color: #3A3B3F;
        margin-top: 20px;
        margin-bottom: 20px;
        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }



    @media only screen and (max-width:1600px) {

        .smashingsignin,.smashingsigninwork {
            width: auto;
        }

    }
    
    @media only screen and (max-width:1460px) {
        .mainbox{
            padding: 60px 70px;
        }

        .mainboxheading{
            width: 50%;
        }
    
      }


      @media only screen and (max-width:1330px) {
          .Smishing-explain-compimg{
              width: 40%;
          }
          
.simishingphone{
    object-fit: fill;
    height: auto;
}
          .Smishing-explain-compflex{
              gap: 150px;
          }
      }



    @media only screen and (max-width:600px) {
  
        // .navbarmain{
        //     padding: 0px 20px;
        // }

        .termslinks{
            display: block;
        }
       
        .frameimg{
            display: block;
        }
       .Smishing-firstbox , .Smishing-explain-secondbox{
          padding: 60px 20px;
       }
   .Smishing-firsthead{
      font-size: 36px;
      width: auto;
   }

   .Smishing-firstabout {
    font-size: 14px;
   }
.Smishing-firstpara{
  font-size: 16px;
}


.Smishing-explain-compimg{
  width: 100%;
  margin: auto;
}

.Smishing-explain-compflex {
display: block;
} 

.Smishing-explain-comptext {
    // padding-top: 25px;
    // margin: auto;
    width: auto;
}


.comptext >h1,.smsihingthirdhead {
    font-size: 30px;
}
.smishthirdbox{
    padding: 30px 60px;

}

.smsihingthird{
    display: block;
}

.simpimg{
    width: 100%;
    margin-top: 30px;
}

.smishing-mechansim-thirdbox{
    padding: 60px 20px;
}

.smishing-mechansim-thirdheading{
    font-size: 36px;
}

.smishing-mechansim-thirdpara{
    font-size: 16px;
}


.smishing-mechansim-mainflexdiv ,.smishing-mechansim-flexdiv{
    display: block;
}

.smishing-mechansim-fleximg{
    width: 57;
}

.fourthbox{
    padding: 60px 20px;

}

.fourthboxmain{
    display: block;
}

.fourthsimphead{
    font-size: 30px;
}

.simishingphone{
    width: 50%;
    margin-bottom: 30px;
    margin-top: 10%;
}
.opportunity-fifthbox{
  margin-top: 0px;
  width: 88%;


}

.opportunity-mainbox{
  padding: 30px 20px;
  flex-direction: column;
  display: block;

}

.opportunity-mainboxheading{
  width: auto;
}
        .opportunity-fiftbtn{
            flex-direction: column;
        }

        .opportunity-fifthimage{
            display: none;
        }

        .frameimg{
          display: block;

          margin: auto;
        // display: block;
        width: 96%;
        padding-top: 40px;
        }

        .frameimg{
            display: block;
        }

        .opportunity-fifthfirstbtn ,.opportunity-fifthsecondbtn, .opportunity-fifthimage {
            width: auto;
        }

        .forthimg{
    width: auto;
        }
        .sixbox{
            padding: 60px 20px;
            margin-top: 20px;
        }

        .sixboxhead{
            padding: 0px;
        }

        .sixboxpara{
            padding-bottom: 30px;
        }

        .footer{
            padding: 60px 20px;
            margin-top: 20px;
        }
.social{
    width: 20%;
}
        .links{
            width: auto;
            flex-direction: column;
            gap: 16px;
        }


        .Smishing-explain-comptext > h1{
            width: auto;
        }

        .foootermain{
            width: auto;
        }

      }





      @media only screen and (max-width:431px) {

        .smishthirdbox{
        padding: 60px 20px;
        }

        .simishingphone{
            width: 100%;
        }
      }

  


}