#homecontainer {
    // background-color:#FFF;

 


    .frameimg{
        display: none;
    }
    .newbtn {

        align-items: center;
        border: 1px solid #EF4444;
        background-color: transparent !important;
        color: #3A3B3F;
        padding: 10px 40px;
        border-radius: 100px;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;

    }

    .signin {
        align-items: center;

        border: 1px solid #EF4444;

        padding: 10px 40px;
        border-radius: 100px;
        background: #EF4444;
        color: #FFF;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }

    .firstbox {
        padding-top: 60px;
        padding-bottom: 80px;
        width: 80%;
        margin: auto;
    }

    .phising {
        color: #3A3B3F;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        margin-bottom: 20px;
    }

    .phisheading {
        color: #3A3B3F;
        text-align: center;

        /* lg/Medium */
        font-family: Poppins !important;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 126%;
        /* 60.48px */
        letter-spacing: -0.96px;
    }

    .phispara {
        color: #888;
        text-align: center;

        /* Text xl/Regular */
        font-family: Poppins !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 30px */
        letter-spacing: -0.4px;
        margin-top: 2px;
        width: 90%;
        margin: auto;
        padding-top: 8px;
    }

    .startedandsiginbtn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        gap: 15px;
        margin-top: 30px;
        margin-bottom: 60px;
    }


    .mission-section-fourthbtn {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: auto;
        gap: 15px;
        margin-top: 30px;

    }

    .secondbox {
        // width: 90%;
        // margin: auto;
        background-image: url("../../../public/Ellipse.png");
        //  background-color: #cccccc;
        background-repeat:round;
        background-size: contain;
        // background-position-y: unset; 
        margin: auto;
        justify-content: center;
        display: flex;
        align-items: center;
        background-position-y: center;

    }


 
    .secondimg {
        width: 83%;
        margin: auto;
    }

    .smishingattack {
        width: 94%;
        margin: auto;
        padding: 120px;
    }


    .smishingattack-third-heading {
        color: #3A3B3F;
        text-align: center;

        /* lg/Medium */
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 126%;
        /* 60.48px */
        letter-spacing: -0.96px;

    }

    .smishingattack-third-para {
        color: #6D727D;
        text-align: center;

        /* Text xl/Medium */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 30px */
        letter-spacing: -0.4px;
        margin-top: 6px;
        margin-bottom: 60px;

    }

    .mainflexdiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .flexdiv {
        display: flex;
        flex-direction: column;
        gap: 120px
    }

    .number {
        color: #EF4444;

        /* lg/Medium */
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 126%;
        /* 60.48px */
        letter-spacing: -0.96px;
        text-align: left;

    }

    .numberhead {
        color: #3A3B3F;

        /* xs/Medium */
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%;
        /* 31.68px */
        letter-spacing: -0.48px;
        margin-top: 30;
        text-align: left;
    }


    .numberpara {
        color: #6D727D;

        /* Text lg/Regular */
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 155%;
        /* 27.9px */
        letter-spacing: -0.36px;
        text-align: left;

    }

    .fleximg {
        width: 61%;
    }


    .mission-section-fourthbox {
        width: 100%;
        margin: auto;
        padding: 120px 120px 60px 0px;
    }

    .mission-section-forthflex {
        display: flex;
        flex-direction: row;
        gap: 80px;
    }

    .mission-section-forthimg {
        width: 48%;
        //         border-radius: 24.87px;
        // border: 5px solid  #F5F5F6;
        // background: #000 !important;
    }



    .mission-section-forthheader {
        color: #3A3B3F;
        text-align: left;
        /* lg/Medium */
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 126%;
        /* 60.48px */
        letter-spacing: -0.96px;
        width: 67%;
        margin-top: 107px;
    }

    .mission-section-forthpara {

        color: #6D727D;
        text-align: left;
        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        margin-top: 25px;
        width: 92%;
    }

    .awareness-section-fifthbox {
        width: 80%;
        margin: auto;
    }

    .awareness-section-mainbox {
        display: flex;
        flex-direction: row;
        gap: 60px;
        border-radius: 36px;
        background: #EF4444;
        padding: 60px 120px;
    }

    .awareness-section-mainboxheading {
        color: #FFF;
        font-family: Poppins;
        font-size: 31px;
        font-style: normal;
        font-weight: 500;
        line-height: 129%;
        /* 46.44px */
        letter-spacing: -0.72px;
        text-align: left;
        margin-top: 22px;
    }

    .awareness-section-mainboxpara {
        color: #FFF;
        text-align: left;
        /* Text lg/Regular */
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 155%;
        /* 27.9px */
        letter-spacing: -0.36px;
        margin-top: 20px;
        margin-bottom: 60px;
    }

    .awareness-section-fifthimage {
        width: 50%;
    }

    .awareness-section-fiftbtn {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .awareness-section-fifthfirstbtn {
        align-items: center;

        border: 1px solid #FFF;

        padding: 16px 40px;
        border-radius: 100px;
        background: #FFF;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        // width: 207px;
    }

    .awareness-section-fifthsecondbtn {

        align-items: center;

        border: 1px solid #FFF;

        // width: 207px;
        padding: 16px 40px;
        border-radius: 100px;
        background: transparent !important;
        color: white;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }


    .rewiews-section-sixboxhead {
        color: #3A3B3F;
        text-align: center;

        /* md/Medium */
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 129%;
        /* 46.44px */
        letter-spacing: -0.72px;
        padding-top: 120px;
    }


    .rewiews-section-sixboxpara {
        color: #6D727D;
        text-align: center;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        margin-top: 4px;
        padding-bottom: 60px;
    }



    .footer {
        padding: 60px 120px;
        margin-top: 120px;
        // width: 50%;
        // margin: auto;
        background: rgb(252, 224, 224);
        background: linear-gradient(0deg, rgba(252, 224, 224, 1) 0%, rgba(229, 231, 232, 1) 100%, rgba(0, 0, 0, 1) 100%);
    }

    .foootermain {
        width: 50%;
        margin: auto;
    }

    .footerlogo {
        width: 30%;
        margin-bottom: 10px;
        // width: 130px;
        // height: 91px;
    }

    .footerpara {
        color: #6D727D;
        text-align: center;

        /* Text sm/Regular */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
        // width: 70%;
        margin: auto;
    }

    .social {
        width: 10%;
    }


    .links {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 50%;
        margin: auto;
    }

    .linksname {
        color: #3A3B3F;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }

    .bg-body-tertiary {
        background-color: transparent !important;
    }


    .termslinks{
        display: flex;
        gap: 100px;
        flex-direction: row;
        justify-content: center;
        margin: auto;
       
    }

    .termslinksname {
        color: #3A3B3F;
        margin-top: 20px;
        margin-bottom: 20px;
        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }


    @media only screen and (max-width:1500px) {
        
      .mission-section-forthheader{
        width: auto;
        margin-top: 50px;
      }

       .mission-section-forthimg {
        width: 50%;
      }


    
        
      }


      @media only screen and (max-width:1468px) {
        .awareness-section-mainbox{
            padding: 60px 70px;
        }

        .awareness-section-mainboxheading{
            width: 50%;
        }
        .awareness-section-fifthimage{
            width: 45%;
        }
      }

      @media only screen and (max-width:600px) {

        // .navbarmain{
        //     padding: 0px 20px;
        // }

        .firstbox{
            padding-bottom: 0px;
        }
        .phisheading{
            font-size: 36px;
        }

        .phispara{
            font-size: 16px;
        }

        .startedandsiginbtn{
flex-direction: column;
        }
        .smishingattack{
            padding: 60px 20px;
        }
        .smishingattack-third-heading{
            font-size: 36px;
        }

        .smishing-attack-third-para{
            font-size: 16px;
        }

        .mainflexdiv{
            flex-direction: column;
        }
        .flexdiv{
            gap: 40px;
        }

        .fleximg{
            width: 40%;
        }

        .mission-section-fourthbox{
            padding: 60px 20px;
        }

        .mission-section-forthflex{
            flex-direction: column;
            gap: 50px;
        }

        .mission-section-forthheader{
            font-size: 36px;
            width: auto;
            margin-top: 10px;
        }

        .mission-section-forthpara{
            width: auto;
        }

        .mission-section-fourthbtn{
            flex-direction: column;

        }

        .awareness-section-mainbox{
            padding:30px 20px;
            flex-direction: column;
            gap: 34px;
        }

        .awareness-section-mainboxheading{
            width: auto;
        }

        .awareness-section-fiftbtn{
            flex-direction: column;
        }

        .awareness-section-fifthimage{
            display: none;
        }
        .awareness-section-fifthbox {
            width: 91%;
            margin: auto;
        }

        // .firstbox{
        //     width: auto;
        //     padding: 60px 20px;
        // }

        .frameimg{
            display: block;
        }

        .fifthfirstbtn ,.fifthsecondbtn, .fifthimage {
            width: auto;
        }

        .mission-section-forthimg{
    width: auto;
        }
        .rewiews-section-sixbox{
            padding: 60px 20px;
        }

        .rewiews-section-sixboxhead{
            padding: 0px;
        }

        .rewiews-section-sixboxpara{
            padding-bottom: 30px;
        }

        .footer{
            padding: 60px 20px;
            margin-top: 0px;
        }
.social{
    width: 20%;
}
        .links{
            width: auto;
            flex-direction: column;
            gap: 16px;
        }

        .termslinks{
            display: block;
        }

        .foootermain{
            width: auto;
          }
  
      
      }



//for navbar only






}