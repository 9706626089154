#contactcontainer {
    .bg-body-tertiary {
        background-color: transparent !important;
    }

    // .nav {
    //     color: #3A3B3F;


    //     font-family: Poppins !important;
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 300;
    //     line-height: 150%;
    //     /* 24px */
    //     letter-spacing: -0.32px;

    // }


    // .navbarmain {
    //     padding: 0px 137px;
    // }


    .newbtn {

        align-items: center;
        border: 1px solid #EF4444;
        background-color: transparent !important;
        color: #3A3B3F;
        padding: 10px 40px;
        border-radius: 100px;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;

    }

    .signin {
        align-items: center;

        border: 1px solid #EF4444;

        padding: 10px 40px;
        border-radius: 100px;
        background: #EF4444;
        color: #FFF;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }



    .contact-firstbox {

        padding: 120px;
        background: rgb(252, 214, 214);
        background: linear-gradient(0deg, rgba(252, 214, 214, 1) 0%);
    }

    .contact-firstabout {
        color: #3A3B3F;
        text-align: center;

        /* Text md/Medium */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        margin-bottom: 20px;
    }

    .contact-firsthead {
        color: #3A3B3F;
        text-align: center;

        /* lg/Medium */
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 126%;
        /* 60.48px */
        letter-spacing: -0.96px;
        width: 57%;
        margin: auto;

    }

    .contact-firstpara {
        color: #575A63;
        text-align: center;

        /* Text xl/Regular */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 30px */
        letter-spacing: -0.4px;
    }


    .secondbox {
        padding: 120px;
    }

    .secondmain {
        display: flex;
        flex-direction: row;
        gap: 60px;
    }

    .contactdiv {
        width: 50%;
    }

    .contactdiv>h3 {
        margin-bottom: 20px;
        color: #3A3B3F;
        text-align: left;
        /* Text md/Medium */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
    }


    .contactdiv>h1 {
        color: #3A3B3F;

        /* lg/Medium */
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 126%;
        /* 60.48px */
        letter-spacing: -0.96px;
        text-align: left;
    }

    .contactdiv>p {
        color: #6D727D;
        text-align: left;
        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        margin-top: 10px;
        // width: 70%;
    }

    .follow {
        color: #3A3B3F;

        /* xs/Medium */
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%;
        /* 31.68px */
        letter-spacing: -0.48px;
        margin-top: 30px;
        text-align: left;
        margin-bottom: 16px;
        margin-top: 80px;
    }

    .contactsocial {
        width: 44px;
        align-items: flex-start;
        display: flex;
        // height: 24px;
    }


    .sendmessage {
        width: 50%;
        padding: 30px 20px;
        border-radius: 20px;
        background: #F7F7F7;
    }

    .nametext {
        color: #424448;
        margin-bottom: 5px;
        /* Text sm/Regular */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        justify-content: left;
        text-align: left;
        margin-top: 10px;
    }



    .nameinput {
        border-radius: 6px;
        border: 1px solid #DFDFDF;
        padding: 12px 16px;
        color: #ABAFB5;
        justify-content: left;
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
    }


    .sendmsgbtn {
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 100px;
        background: #EF4444;
        padding: 17px;
        color: v #FFF;
        text-align: center;
        width: 98%;
      

        /* Text md/Medium */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
    }

    .contactdisplay {
        display: flex;
        gap: 10px;
        flex-direction: row;
    }

    .review-sixbox {
        margin-top: 120px;
    }


    .review-sixboxpara {
        color: #6D727D;
        text-align: center;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        margin-top: 4px;
        padding-bottom: 60px;
    }

 


    .footer {
        padding: 60px 120px;
        margin-top: 120px;
        // width: 50%;
        // margin: auto;
        background: rgb(252, 224, 224);
        background: linear-gradient(0deg, rgba(252, 224, 224, 1) 0%, rgba(229, 231, 232, 1) 100%, rgba(0, 0, 0, 1) 100%);
    }

    .foootermain {
        width: 50%;
        margin: auto;
    }

    .footerlogo {
        width: 30%;
        margin-bottom: 10px;
        // width: 130px;
        // height: 91px;
    }

    .footerpara {
        color: #6D727D;
        text-align: center;

        /* Text sm/Regular */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
        // width: 70%;
        margin: auto;
    }

    .social {
        width: 10%;
    }


    .links {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 50%;
        margin: auto;
    }

    .linksname {
        color: #3A3B3F;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }



    .termslinks{
        display: flex;
        gap: 100px;
        flex-direction: row;
        justify-content: center;
        margin: auto;
       
    }

    .termslinksname {
        color: #3A3B3F;
        margin-top: 20px;
        margin-bottom: 20px;
        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }


.legal{
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.consent{
    color: #6D727D;
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: -0.32px;
}
    


    @media only screen and (max-width:600px) {

        // .navbarmain{
        //     padding: 0px 20px;
        // }

.termslinks{
    display: block;
}

        .phisheading{
            font-size: 36px;
        }

        .phispara{
            font-size: 16px;
        }

        .contact-firstbox,.secondbox{
            padding: 60px 20px;
        }

        .contactdiv {
width: auto;
        }

        .sendmessage{
            width: auto;
            padding: 30px 20px;
            margin-top: 30px;
        }
        .contactdiv > h1{
            font-size: 36px;
        }

        .secondmain{
            display: block;
        }
.contact-firsthead{
    font-size: 36px;
    width: auto;
}

.contact-firstpara{
    font-size: 16px;
    margin-top: 14px;
}

.foootermain{
    width: auto;
  }

        .firstbtn{
flex-direction: column;
        }

        .review-{
            padding: 60px 20px;
            margin-top: 0px;
        }

        .review-sixboxhead{
            padding: 0px;
        }

        .review-sixboxpara{
            padding-bottom: 30px;
        }

        .footer{
            padding: 60px 20px;
            margin-top: 0px;
        }

        .review-sixbox {
margin-top: 0px;

        }
.social{
    width: 20%;
}
        .links{
            width: auto;
            flex-direction: column;
            gap: 16px;
        }
    }



}