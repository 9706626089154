#privacycontainer {
    // background-color:#FFF;

 


    .frameimg{
        display: none;
    }
    .newbtn {

        align-items: center;
        border: 1px solid #EF4444;
        background-color: transparent !important;
        color: #3A3B3F;
        padding: 10px 40px;
        border-radius: 100px;
        color: #EF4444;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;

    }

    .signin {
        align-items: center;

        border: 1px solid #EF4444;

        padding: 10px 40px;
        border-radius: 100px;
        background: #EF4444;
        color: #FFF;
        text-align: center;

        /* Text sm/Medium */
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
    }



.privacypolicy{
    width: 96%;
    margin: auto;
}

    .mainheading{

        color: #3A3B3F;
        text-align: center;
        font-family: Poppins;
        font-size: 38px;
        font-style: normal;
        font-weight: 500;
        line-height: 126%;
        letter-spacing: -0.96px;
        margin-top: 20px;
        margin-bottom: 20px;

     }

     .mainpara{
        color: #575A63;
        text-align: left;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: -0.4px;
    

     }


     .policyheading{
        color: #EF4444;
        text-align: left;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 126%;
        letter-spacing: -0.96px;
        margin-top: 20px;
        margin-bottom: 20px;
     }


     .policypara{
        color: #575A63;
        text-align: left;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: -0.4px;
        margin-top: 10px;
    

     }

       .bold{
        color: #000;
        font-weight: 500;
       }



    .footer {
        padding: 60px 120px;
        margin-top: 120px;
        // width: 50%;
        // margin: auto;
        background: rgb(252, 224, 224);
        background: linear-gradient(0deg, rgba(252, 224, 224, 1) 0%, rgba(229, 231, 232, 1) 100%, rgba(0, 0, 0, 1) 100%);
    }

    .foootermain {
        width: 50%;
        margin: auto;
    }

    .footerlogo {
        width: 30%;
        margin-bottom: 10px;
        // width: 130px;
        // height: 91px;
    }

    .footerpara {
        color: #6D727D;
        text-align: center;

        /* Text sm/Regular */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 19.6px */
        letter-spacing: -0.28px;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
        // width: 70%;
        margin: auto;
    }

    .social {
        width: 10%;
    }


    .links {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 50%;
        margin: auto;
    }

    .linksname {
        color: #3A3B3F;

        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }

    .bg-body-tertiary {
        background-color: transparent !important;
    }


    .termslinks{
        display: flex;
        gap: 100px;
        flex-direction: row;
        justify-content: center;
        margin: auto;
       
    }

    .termslinksname {
        color: #3A3B3F;
        margin-top: 20px;
        margin-bottom: 20px;
        /* Text md/Regular */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        /* 24px */
        letter-spacing: -0.32px;
        cursor: pointer;
    }







      @media only screen and (max-width:600px) {

        // .navbarmain{
        //     padding: 0px 20px;
        // }

        .firstbox{
            padding-bottom: 0px;
        }
        .phisheading{
            font-size: 36px;
        }

        .phispara{
            font-size: 16px;
        }

        .privacypolicy{
            width: 89%;
            margin: auto;
        }
     
        .mainheading{

            color: #3A3B3F;
            text-align: center;
            font-family: Poppins;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 126%;
            letter-spacing: -0.96px;
            margin-top: 20px;
            margin-bottom: 20px;
    
         }
    
         .mainpara{
            color: #575A63;
            text-align: left;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            letter-spacing: -0.4px;
        
    
         }
    
    
         .policyheading{
            color: #EF4444;
            text-align: left;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 126%;
            letter-spacing: -0.96px;
            margin-top: 20px;
            margin-bottom: 20px;
         }
    
    
         .policypara{
            color: #575A63;
            text-align: left;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            letter-spacing: -0.4px;
            margin-top: 10px;
        
    
         }
   
    .termslinks{
        display: block;
    }
 

        .footer{
            padding: 60px 20px;
            margin-top: 0px;
        }
.social{
    width: 20%;
}
        .links {
            width: auto;
            flex-direction: column;
            gap: 16px;
        }

        .foootermain{
            width: auto;
          }
  
      
      }



//for navbar only






}